import React, { useState } from 'react';
import { fetchData } from '../../../utils/datasource';
import { useNavigate } from 'react-router-dom';
import './Popup.css';

const PopupLiberar = ({ onConfirm, numIde, fil, idUser, userName }) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        setIsPopupVisible(true);
    };

    const handleConfirm = async () => {
        setIsPopupVisible(false);
        if (onConfirm) onConfirm('Liberar');

        try {
            const url = `/DCAPROVPED/api/dc/getrespuser?NUMIDE=${numIde}&FIL=${fil}&TPRESP=A&MOTREJ=null&IDUSER=${idUser}&USR=${userName}`;
            const response = await fetchData(url, 'GET');

            if (response.ok) {
                window.showAlert('success', 'Liberação confirmada com sucesso!');
                // if (onConfirm) onConfirm({ action: 'Libera', motivo: selectedMotivo });
                if (onConfirm) onConfirm('Liberar');
                navigate('/aprovacao_desconto_os_orcamento'); // Redireciona após sucesso
            } else {
                window.showAlert('danger', 'Erro ao confirmar a liberação.');
            }
        } catch (err) {
            window.showAlert('danger', 'Erro ao confirmar a liberação. Verifique sua conexão ou os dados enviados.');
        } finally {
            setIsPopupVisible(false);
        }


    };

    const handleCancel = () => {
        setIsPopupVisible(false);
    };

    return (
        <>
            <button className="btn btn-success w-100" onClick={handleClick}>
                Liberar
            </button>
            {isPopupVisible && (
                <div className="modal-overlay">
                    <div className="modal-container">
                        <h5>Confirmar Liberação</h5>
                        <p>Você tem certeza que deseja liberar este orçamento?</p>
                        <div className="modal-actions">
                            <button className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar
                            </button>
                            <button className="btn btn-success" onClick={handleConfirm}>
                                Confirmar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PopupLiberar;
