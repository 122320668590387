import React from 'react';
import { Link, useNavigate   } from 'react-router-dom';

export const MainHeader = ({ headerData }) => {
  const navigate = useNavigate();

  return <div className="container-fluid main-header fixed-top p-0">
    <div className="d-flex justify-content-between themeTextColorPrimary p-2">
      <div>
        <h1 className="h6 mb-0 fw-bold">{headerData?.title ?? '{title}'}</h1>
        <small>{headerData?.subtitle ?? '{subtitle}'}</small>
      </div>

      <div className="p-2">
        <div>
          

          <Link to={'/aprovacao_desconto_os_orcamento'}><i className="fas fa-arrow-left me-4 themeTextColorPrimary"></i></Link>
          {/* <Link to={'/home'}><i className="fas fa-redo-alt me-4 themeTextColorPrimary"></i></Link> */}
          <Link to={'/home'}><i className="fas fa-home themeTextColorPrimary"></i></Link>
        </div>
      </div>
    </div>
    <div className="line-green w-100"></div>
    <div className="line-yellow w-100"></div>
  </div>
}