import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchData } from '../../../utils/datasource';
import { useNavigate } from 'react-router-dom';

const PopupRejeitar = ({ onConfirm, numIde, fil, idUser, userName }) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [motivos, setMotivos] = useState([]);
    const [selectedMotivo, setSelectedMotivo] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [validationError, setValidationError] = useState(''); // Gerencia o erro de validação
    const navigate = useNavigate();

    const fetchMotivos = async () => {
        try {
            setLoading(true);
            const response = await fetchData(`/DCAPROVPED/api/dc/getmovrej`, 'GET');
            const result = await response.json();
            setMotivos(result.MOTIVOS);
            setLoading(false);
        } catch (err) {
            setError('Erro ao buscar motivos de rejeição.');
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isPopupVisible) {
            fetchMotivos();
        }
    }, [isPopupVisible]);

    const handleClick = () => {
        setIsPopupVisible(true);
    };

    const handleConfirm = async () => {
        if (!selectedMotivo) {
            // setValidationError('Por favor, selecione um motivo para a rejeição.');

            window.showAlert('danger', 'Por favor, selecione um motivo para a rejeição.');
            // danger
            return;
        }

        try {
            const url = `/DCAPROVPED/api/dc/getrespuser?NUMIDE=${numIde}&FIL=${fil}&TPRESP=R&MOTREJ=${selectedMotivo}&IDUSER=${idUser}&USR=${userName}`;
            const response = await fetchData(url, 'GET');

            if (response.ok) {
                window.showAlert('success', 'Rejeição confirmada com sucesso!');
                if (onConfirm) onConfirm({ action: 'Rejeitar', motivo: selectedMotivo });
                navigate('/aprovacao_desconto_os_orcamento'); // Redireciona após sucesso
            } else {                
                window.showAlert('danger', 'Erro ao confirmar a rejeição.');
            }
        } catch (err) {            
            window.showAlert('danger', 'Erro ao confirmar a rejeição. Verifique sua conexão ou os dados enviados.');
        } finally {
            setIsPopupVisible(false);
        }
    };

    const handleCancel = () => {
        setIsPopupVisible(false);
        setSelectedMotivo('');
        setValidationError(''); // Limpa o erro ao cancelar
    };

    return (
        <>
            <button className="btn btn-danger w-100" onClick={handleClick}>
                Rejeitar
            </button>
            {isPopupVisible && (
                <div className="modal-overlay">
                    <div className="modal-container">
                        <h5>Confirmar Rejeição</h5>
                        <p>Você tem certeza que deseja rejeitar este orçamento?</p>
                        {loading ? (
                            <p>Carregando motivos...</p>
                        ) : error ? (
                            <p className="text-danger">{error}</p>
                        ) : (
                            <div className="form-group">
                                <label htmlFor="motivo-select">Selecione um motivo:</label>
                                <select
                                    id="motivo-select"
                                    className="form-control"
                                    value={selectedMotivo}
                                    onChange={(e) => {
                                        setSelectedMotivo(e.target.value);
                                        setValidationError(''); // Limpa o erro ao selecionar
                                    }}
                                >
                                    <option value="">Selecione um motivo</option>
                                    {motivos.map((motivo) => (
                                        <option key={motivo.codi_motivo} value={motivo.codi_motivo}>
                                            {motivo.desc_motivo}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className="modal-actions">
                            <button className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar
                            </button>
                            <button className="btn btn-danger" onClick={handleConfirm}>
                                Confirmar
                            </button>
                        </div>
                        {/* Mensagem de validação */}
                        {validationError && <p className="text-danger mt-2">{validationError}</p>}
                    </div>
                </div>
            )}
        </>
    );
};

PopupRejeitar.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    numIde: PropTypes.string.isRequired,
    fil: PropTypes.string.isRequired,
    idUser: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
};

export default PopupRejeitar;
