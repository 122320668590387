import React from 'react';
import { useLocation } from 'react-router-dom';
import './DetalheOrcamento.css';
import { MainContainer } from '../../../components/template/MainContainer';
import DetalheOrcamentoItens from './DetalheOrcamentoItens';
import { formatarDataHora } from '../../../utils/formatarDataHora';
import PopupRejeitar from './PopupRejeitar';
import PopupLiberar from './PopupLiberar';
import { useAuth } from '../../../context/AuthContext';

const DetalheOrcamento = () => {
    const { user } = useAuth();
    const location = useLocation();
    const data = location.state?.data;

    // Verifique se os dados estão disponíveis
    if (!data) {
        return <p>Dados do orçamento não disponíveis.</p>;
    }

    const handleAction = (action) => {
        // console.log(`${action} confirmado para orçamento ${data?.orcamento}`);
        // Adicione aqui a lógica para lidar com a ação
    };

    return (
        <MainContainer headerData={{ title: `Liberando a Venda`, subtitle: `Descontos` }}>

            <div className="container-fluid detalhe-orcamento-container themeTextColorPrimary mb-2">
                {/* Informações principais */}
                <div className="row mb-2">
                    <div className="col-12">
                        <label>Cliente:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={`${data?.codi_cli}/${data?.loja_cli} - ${data?.nome_cli}` ?? 'Informação da empresa não disponível'}
                            readOnly
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <label>Data/Hora:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formatarDataHora(`${data?.data_ocorren} - ${data?.hora_ocorren}`)}
                            readOnly
                        />
                    </div>
                    <div className="col-6">
                        <label>Usuário:</label>
                        <input type="text" className="form-control" value={data?.usuario} readOnly />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <label>Orçamento:</label>
                        <input type="text" className="form-control" value={data.orcamento} readOnly />
                    </div>
                    <div className="col-6">
                        <label>Nro Identif.:</label>
                        <input type="text" className="form-control" value={data?.nro_identif} readOnly />
                    </div>
                </div>

                <DetalheOrcamentoItens FIL={data?.filial} NUMIDE={data?.nro_identif} />

                {/* Botões de ação */}
                <div className="row mt-4">
                    <div className="col-6">

                        <PopupRejeitar
                            onConfirm={handleAction}
                            numIde={data?.nro_identif}
                            fil={data?.filial}
                            idUser={user?.id}
                            userName={user?.userName}
                        />

                        {/* <PopupRejeitar onConfirm={handleAction} /> */}
                    </div>
                    <div className="col-6">
                        <PopupLiberar
                            onConfirm={handleAction}
                            numIde={data?.nro_identif}
                            fil={data?.filial}
                            idUser={user?.id}
                            userName={user?.userName}
                        />
                    </div>
                </div>
            </div>
        </MainContainer>
    );
};

export default DetalheOrcamento;
