import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider, useAuth } from '../context/AuthContext';
import RoutesAuthenticated from './RoutesAuthenticated';
import RoutesUnauthenticated from './RoutesUnauthenticated';
import Alert from '../components/template/Alert';
import Preloader from '../components/template/Preloader';
import '../assets/styles/App.css';

// const LoadPanel = () => <Preloader />

const AppRender = () => {
    const { user, loading } = useAuth()

    // if (loading) return <LoadPanel visible={true} />
    if (loading) return <Preloader />
    if (user) return <RoutesAuthenticated />
    return <RoutesUnauthenticated />

}

function App() {
    // const [alert, setAlert] = useState({ visible: false, type: '', message: '' });
    const [alert, setAlert] = useState({ visible: false, type: 'info', message: '' });


    // Função para mostrar o alerta
    const showAlert = (type, message) => setAlert({ visible: true, type, message });

    // Função para fechar o alerta
    const closeAlert = () => setAlert({ ...alert, visible: false });

    // Adicione a função showAlert no escopo global para ser usada em qualquer lugar
    window.showAlert = showAlert;

    return (
        <AuthProvider>
            <Alert
                visible={alert.visible}
                type={alert.type}
                message={alert.message}
                onClose={closeAlert}
            />
            <Router>
                <AppRender />
            </Router>
        </AuthProvider>
    );
}

export default App;
