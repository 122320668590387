import React, { useEffect, useState } from 'react';
import './Preloader.css'; // Importa o CSS do preloader

const Preloader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula um carregamento de 1.5 segundos
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    loading && (
      <div className="preloader">
        <div className="spinner">
          <span className="spinner-text">DC</span>
          <div className="spinner-border"></div>
        </div>
      </div>
    )
  );
};

export default Preloader;
