import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, sendSignInRequest } from '../api/AuthAPI';


function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();      

      if (result.isOk) {
        setUser(result.data);
      }

      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (username, password) => {

    
    if (username == 'hmg') {
      const loginData = {
        "access_token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InBKd3RQdWJsaWNLZXlGb3IyNTYifQ.eyJpc3MiOiJUT1RWUy1BRFZQTC1GV0pXVCIsInN1YiI6InBhdWxvc2liZSIsImlhdCI6MTczNzA1NjAxOCwidXNlcmlkIjoiMDAwNzQ5IiwiZXhwIjoxNzM3MDU5NjE4LCJlbnZJZCI6InNpZ2FvZmljaWFsIn0.QoLkPfRDsNbY8d5xucTjJRpBAjzhBVBxoaHIcSd9JyhShHqiQJmUtcatvnADm3JqvSEzaOXxNVEi8jrac9ysUo6WAPQ0UQxbe3UtvYjIdB1CpezTaAh8Wcj6zeGkkISEO_apE_TXSOmxUbn9r6WAVoWHgwnCvNjHu1wrkU7lOxu550JUb7IsyrNL2Gj5ssE8JGrMkDA3JTbxq986IoETsaFkHgHqF0JkPrCeVqN9OvGJoYs1WV_nG8hRQm9Hd40WN6B0_IRtX0LQFCzyAybdDUEOdKfJul5OhLPZOgH_9CiTn_jPUauCZF79A2nrWmUcw7E69v4PfPB05SLIb8SBdg",
        "refresh_token": "sLhRjNmBSvjmDXAJybIT6p7s.nqt0x_W8NrLKMGsd2rBysvGSadUvfDo39A8L-DKJST6CZfJaMJ0CCXlX0rgQRB8k8-JgWEq6QkVPyJb_otu7IOtaQH213lLxCaK71j7CzOg48f9FqVVI3Df6xoGR2Xdqycfs0zVw8whbQn84jBPCafXJoeJbIGPJnYVFFlN5JlSGomGMY5digPuexCJC5ehUEjvAjx2HxH3IbVQF_Jc.CSMCzSer6VHabMkLr6FR9_l_3tSrqkzEcp0AoyO0jf06qt73g71KgNyx_mLCUEGVDh-_ISJ84S_OZHwAeoNlbxKRulFGM05EKrHtB4iLipvVa1mgS9bi0ZXSK0LxRLcm3TBEXEaEyI12wAGGOgY6t8notEqMJeVHZcvtUHJ5A0W4LgRblNp509T3ZQ1YqNcno2mRgtm1Ew-Q3sn6BbllSVDaJXY07y-Bo3FxfU1vTjGJr4OlV0P1OpHI7kiGSLmzySJzV6Bvw7hBVTkvYCXT_QuAHbagMs2-HvNspv6TlGA-IdqVXC0_Wwzd8KwTT0FBdWmu0jHrqmzRWHkcvGxIhw",
        "scope": "default",
        "token_type": "Bearer",
        "expires_in": 3600
      }

      const credentials = btoa(`${username}:${password}`);

      localStorage.setItem('authCredentials', JSON.stringify({
        ...loginData,
        credentials
      }));

      const userResultHMG = await getUser(); // Recarrega os dados do usuário autenticado
      setUser(userResultHMG.data);
      
      return { isOk: true }
    }

    const result = await sendSignInRequest(username, password);

    if (result.isOk) {
      const userResult = await getUser(); // Recarrega os dados do usuário autenticado
      if (userResult.isOk) {
        setUser(userResult.data);
      }
    }

    return result;

  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('authCredentials')
    setUser(undefined)
  }, []);


  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
