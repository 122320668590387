import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CabecalhoBotaoOrcamento.css';
import { formatarDataHora } from '../../utils/formatarDataHora';

const CabecalhoBotaoOrcamento = ({ data }) => {
    const navigate = useNavigate();

    const title = `${data?.filial} - ${data?.desc_ocorren}` ?? 'Título padrão';
    const companyInfo = `${data?.codi_cli}/${data?.loja_cli} - ${data?.nome_cli}` ?? 'Informação da empresa não disponível';
    const orderInfo = `OS: ${data?.orcamento}` ?? 'Informação do pedido não disponível';
    let dateInfo = `${data?.data_ocorren} - ${data?.hora_ocorren}` ?? 'Data não disponível';
    const idInfo = `Nro Ident.: ${data?.nro_identif}` ?? 'Identificação não disponível';
    const contactPerson = data?.usuario ?? 'Pessoa de contato não disponível';

    dateInfo = formatarDataHora(dateInfo)

    return (
        <div className="container container-box themeTextColorPrimary mb-2" role="button"
            onClick={() => navigate("/aprovacao_desconto_os_orcamento_detalhe", { state: { data } })}
        >
            <div className="row">
                <div className="col-12">
                    <h5 className="fw-bold">{title}</h5>
                </div>
            </div>

            <div className="row">
                <div className="col-8">
                    <p className="custom-text mb-0">{companyInfo}</p>
                    <p className="custom-text mb-0">{orderInfo}</p>
                    <p className="custom-text mb-0">{dateInfo}</p>
                </div>
                <div className="col-4 d-flex flex-column justify-content-end text-end">
                    <p className="custom-text mb-0">{idInfo}</p>
                    <p className="custom-text mb-0">{contactPerson}</p>
                </div>
            </div>

        </div>
    );
};

export default CabecalhoBotaoOrcamento;
