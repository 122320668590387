import React from 'react';
import PropTypes from 'prop-types';
import './Alert.css'
const Alert = ({ visible, type, message, onClose }) => {
    if (!visible) return null;

    return (
        <div
            className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-alert"
            style={{ zIndex: 1080 }} // Garante que o alerta esteja acima de outros elementos
        >
            <div className={`alert alert-${type} alert-dismissible fade show`} role="alert">
                {message}
                <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
            </div>
        </div>
    );
};

Alert.propTypes = {
    visible: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['success', 'danger', 'warning', 'info']).isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Alert;
