// import React from 'react';
import './DetalheOrcamentoItens.css';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchData } from '../../../utils/datasource';
import dadosOrcamento from './dadosOrcamento.json';

// const DetalheOrcamentoItens = () => {

const DetalheOrcamentoItens = ({ FIL, NUMIDE }) => {
    const [itens, setItens] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchItens = async () => {
            try {
                setLoading(true); // Inicia o carregamento
                const response = await fetchData(`/DCAPROVPED/api/dc/getiteped?FIL=${FIL}&NUMIDE=${NUMIDE}`, 'GET');
                
                if (!response.ok) {
                    throw new Error('Erro ao buscar os itens.');
                }

                const result = await response.json();
                setItens(result.PECAS); // Atualiza o estado com os itens recebidos
            } catch (err) {
                setError(err.message); // Captura o erro
            } finally {
                setLoading(false); // Finaliza o carregamento
            }
        };

        fetchItens();
    }, [FIL, NUMIDE]);

    if (loading) {
        return <p>Carregando itens...</p>;
    }

    if (error) {
        return <p className="text-danger">Erro: {error}</p>;
    }
        
    if (itens.length === 0) {
        return <p>Nenhum item encontrado.</p>;
    }

    const data = dadosOrcamento;

    return <>
        <div className="section-header fw-bold h6 mt-4 themeBGColorPrimary themeTextColorSecondary borderRadius2">Peças:</div>

        <div className="items-container">
            {itens.map((item, index) => (
                <div key={index} className="item-box mb-2 p-2">
                    <div className="d-flex justify-content-between align-items-center mb-2 item-header">
                        <strong>{item.codi_prod} - {item.desc_prod}</strong>
                        <strong>{item.price}</strong>
                    </div>
                    <div className="row text-center">
                        <div className="col-4">
                            <p>Des.Permit.</p>
                            <p>{item.des_permit}</p>
                        </div>
                        <div className="col-4">
                            <p>Des.Desej.</p>
                            <p>{item.des_deseja}</p>
                        </div>
                        <div className="col-4">
                            <p>Marg.Lucro</p>
                            <p>{item.mar_lucro}</p>
                        </div>
                    </div>

                    <div className="row text-center">
                        <div className="col-4">
                            <p>Val.Orig.</p>
                            <p>{item.val_origin}</p>
                        </div>
                        <div className="col-4">
                            <p>Val.Permit.</p>
                            <p>{item.val_permit}</p>
                        </div>
                        <div className="col-4">
                            <p>Val.Desej.</p>
                            <p>{item.val_deseja}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </>

};

export default DetalheOrcamentoItens;
